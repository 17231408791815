<template>
  <v-container fluid>
    <v-row justify="space-around">
      <v-col 
        cols="10"
        sm="6"
        md="6"
        lg="6"
        v-for="(item, index) in items"
        :key="index"
      >
        <v-card min-width="550px">
          <v-card-title class="text-h3 font-weight-medium">
           {{ item.name }}
          </v-card-title>
          <v-divider></v-divider>
          <v-list>
            <v-list-item v-for="(key, index) in filteredKeys" :key="index">
              <v-list-item-content>
                {{ item[key.toLowerCase()].name }}:
              </v-list-item-content>
              <v-list-item-content class="text-wrap d-flex justify-end">
                {{
                  key.toLowerCase() == "lastpasswordchange"
                    ? format(
                        new Date(item[key.toLowerCase()].value),
                        "dd.MM.yyyy"
                      )
                    : item[key.toLowerCase()].value
                }}
              </v-list-item-content>
            </v-list-item>
          </v-list>
          <v-list>
            <v-list-item v-if="!editEmail">
              <v-list-item-content>
                {{ item.email.name }}:
              </v-list-item-content>
              <v-list-item-content class="text-wrap d-flex justify-end">
                {{ item.email.value }}
              </v-list-item-content>
              <v-list-item-action>
                <v-btn v-if="!isEditingEmail" icon @click="isEditingEmail = true">
                  <v-icon>mdi-pencil</v-icon>
                </v-btn>
              </v-list-item-action>
            </v-list-item>
            <v-list-item v-if="isEditingEmail">
              <v-list-item-content>
                <v-text-field
                  v-model="options.editedItem.email"
                  label="Адрес электронной почты"
                  filled="false"
                  placeholder="address@mail.com"
                  autofocus=true
                  :hint="`Введите почту в формате ‘address@mail.com’`"
                  :rules="[rules.required, rules.email]"
                ></v-text-field>
              </v-list-item-content>
              <v-list-item-action>
                <v-btn icon @click="editEmail = false; saveEmail()">
                  <v-icon>mdi-check</v-icon>
                </v-btn>
              </v-list-item-action>
            </v-list-item>
          </v-list>
          <v-list>
            <v-list-item v-if="!editPhone">
              <v-list-item-content>
                {{ item.phone.name }}:
              </v-list-item-content>
              <v-list-item-content class="text-wrap d-flex justify-end">
                {{ item.phone.value }}
              </v-list-item-content>
              <v-list-item-action>
                <v-btn v-if="!isEditingPhone" icon @click="isEditingPhone = true">
                  <v-icon>mdi-pencil</v-icon>
                </v-btn>
              </v-list-item-action>
            </v-list-item>
            <v-list-item v-if="isEditingPhone">
              <v-list-item-content>
                <v-text-field
                  v-model="newPhone"
                  label="Телефон"
                  placeholder="7775552233"
                  :hint="`Введите номер в формате 7778885566`"
                  :rules="[rules.required, rules.phone]"
                ></v-text-field>
              </v-list-item-content>
              <v-list-item-action>
                <v-btn icon @click="editPhone = false; savePhone()">
                  <v-icon>mdi-check</v-icon>
                </v-btn>
              </v-list-item-action>
            </v-list-item>
          </v-list>
          <v-divider></v-divider>
          <v-card-actions class="justify-center">
            <v-btn text @click="showUla = true" color="primary">
              Посмотреть пользовательское соглашение
            </v-btn>
          </v-card-actions>
          <v-divider></v-divider>
          <v-list>
            <v-list-item>
              <v-list-item-content>
                <v-card-actions class="justify-center">
                  <v-btn 
                  color="blue darken-4"
                  :to="path"
                  >
                    Сменить пароль
                  </v-btn>
                </v-card-actions>
              </v-list-item-content>
            </v-list-item>
          </v-list>
          <profile-ula :showULA="showUla" @close-ula="closeUla" />
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import { get, sync } from "vuex-pathify";
import { mapActions } from "vuex";
import { capitalizeString } from "@/util/helpers.js";
import { rules } from "@/util/rules";
import { format } from "date-fns";

export default {
  name: "Profile",
  components: {
    ProfileUla: () => import("@/components/ULAProfile.vue")
  },

  data() {
    return {
      path: '/app/user/profile/changepass',
      showUla: false,
      role: "",
      keys: [
        "Organization",
        "Iin",
        "User",
        "Role",
        "LastPasswordChange",
        "Name",
      ],
      items: [],
      rules,
      editEmail: "",
      editPhone: "",
      newPhone: "",
      newEmail: "",
      isEditingPhone: false,
      isEditingEmail: false
    };
  },

  computed: {
    profile: get("auth/profile"),
    options: get("reference/optionsUser"),
    filteredKeys() {
      return this.keys.filter(key => key !== "Name");
    }
  },
  methods: {
    format,
    ...mapActions({
      getProfile: "auth/getProfile",
      notification: "auth/notification"
    }),
    initialize() {
      this.getProfile().then(() => {
        this.transformData(this.profile);
      });
    },
    transformData(data) {
      const result = data.map(item => {
        const itemObj = {
          name: item.person.firstNameRu + " " + item.person.lastNameRu,
          organization: {
            name: "Организация",
            value: item.organization.nameRu
          },
          email: {
            name: "Адрес электронной почты",
            value: item.email
          },
          phone: {
            name: "Телефон",
            value: item.phoneNumber
          },
          user: {
            name: "Учетная запись",
            value: item.userName
          },
          role: {
            name: "Роль",
            value: this.role
          },
          iin: {
            name: "ИИН",
            value: item.iin
          },
          lastpasswordchange: {
            name: "Дата последнего изменения пароля",
            value: item.lastPasswordChange
          }
        };

        return itemObj;
      });
      this.items = result;
    },
    closeUla() {
      this.showUla = false;
    },
    saveEmail() {
      // if (!this.editEmail) return;

      const email = this.options.editedItem.email;
      this.items[0].email.value = email;

      console.log({ email });

      this.$http
        .post("api/account/user/changeemail", {
          newEmail: email
        })
        .then(response => {
          if (response.status === "Ok") this.notification(response.text);
        });
      this.isEditingEmail = false
    },
    savePhone() {
      // if (!this.editPhone) return;

      const phone = this.newPhone;
      this.items[0].phone.value = phone;

      console.log({ phone });

      this.$http
        .post("api/account/user/changephone", {
          newPhone: phone
        })
        .then(response => {
          if (response.status === "Ok") this.notification(response.text);
        });
      this.isEditingPhone = false
    },
  },
  created() {
    this.role = capitalizeString(
      JSON.parse(localStorage.getItem("user")).role.toLowerCase()
    );
    this.initialize();
  }
};
</script>
